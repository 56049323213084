/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react';
import Props from './SideMenu.types';
import SwitchApplications from './SwitchApplications';
import WeKnowAvatar from '../WeKnowAvatar/WeKnowAvatar';
import { AvatarModeTypes } from '../WeKnowAvatar/WeKnowAvatar.types';
import { useAppContext } from '../../store/app-context';
import { Link, NavLink } from '../../helper/WCILink';
import SideMenuLogo from './SideMenuLogo';
import AppSwitcherUsed from './AppSwitcherUsed';
import useCookies from '../../hooks/useCookies';

export default function SideMenuNavItems({
  menuItems,
  closeSidebar,
}: Readonly<Props>): JSX.Element {
  const { appData } = useAppContext();
  const { cookies } = useCookies();
  const showAppSwitcherPulse = cookies.get('isAppSwitcherUsed') !== 'true';
  const [appSwitcherOpened, setAppSwitcherOpened] = useState(false);

  return (
    <>
      <div className="flex-1 pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-2 gap-2">
          {!appSwitcherOpened && showAppSwitcherPulse && <AppSwitcherUsed />}
          <SwitchApplications isOpened={setAppSwitcherOpened} />
          <SideMenuLogo />
        </div>
        <nav className="mt-5 flex-1 px-2 space-y-1">
          {menuItems.map((item) => (
            <div key={item.name}>
              {item.to && (
                <NavLink
                  to={item.to}
                  className={({ isActive }) => {
                    const classes = [
                      'group flex items-center px-4 py-2 text-sm font-medium rounded-md',
                    ];
                    if (isActive) {
                      classes.push('bg-black bg-opacity-50 text-white');
                    } else {
                      classes.push(
                        'text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white'
                      );
                    }
                    return classes.join(' ');
                  }}
                  end={item.to === '/'}>
                  {item.name}
                  {item.name === 'Follow Up' &&
                    appData.canCascadeStatus?.can_cascade && (
                      <span className="text-red-500 font-bold">&nbsp;!</span>
                    )}
                </NavLink>
              )}
              {item.url && (
                <a
                  className="flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white"
                  href={item.url}
                  rel="noopener noreferrer"
                  target="_blank">
                  {item.name}
                </a>
              )}
              {item.onClickHandler && (
                <button
                  type="button"
                  className="w-full flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-white bg-opacity-10 hover:text-white"
                  onClick={item.onClickHandler}
                  key={item.name}>
                  {item.name}
                </button>
              )}
            </div>
          ))}
        </nav>
      </div>
      <div className="flex-shrink-0 flex bg-weknow-dark p-4">
        <div className="flex flex-shrink-0 justify-start items-center w-full">
          <Link to="/profile" onClick={closeSidebar}>
            <WeKnowAvatar mode={AvatarModeTypes.MY_PROFILE} size={40} />
          </Link>
          <div className="ml-3">
            <Link
              to="/profile"
              onClick={closeSidebar}
              className="text-sm font-medium text-white">
              {appData.nickname}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
