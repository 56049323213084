import { ReactComponent as SpinnerGIF } from '../../assets/svg/spinner.svg';
import Props from './Spinner.types';

function Spinner({ low }: Readonly<Props>): JSX.Element {
  const className = low ? 'spinner-low' : 'spinner';
  return (
    <div className={className}>
      <SpinnerGIF className="w-32" />
    </div>
  );
}

export default Spinner;
