import { Outlet } from 'react-router';

import Props from './ProtectedEvaluator.types';
import { Navigate } from '../helper/WCINavigate';

function ProtectedEvaluator({
  isEvaluator = false,
}: Readonly<Props>): JSX.Element {
  if (!isEvaluator) {
    // user is not admin
    return <Navigate to="/" />;
  }
  return <Outlet />;
}

export default ProtectedEvaluator;
