import {
  NavigateProps,
  Navigate as ReactRouterNavigate,
  useParams,
} from 'react-router';

/**
 * Custom React Router Navigate component that automatically adds the `prj_label` prefix
 */
export function Navigate({
  to,
  ...rest
}: Readonly<NavigateProps>): JSX.Element {
  const { prj_label } = useParams();

  // Return the original Navigate component with the prefixed path
  return (
    <ReactRouterNavigate to={prj_label ? `/${prj_label}${to}` : to} {...rest} />
  );
}
