import { useState } from 'react';
import { NavLink } from 'react-router';
import { ReactComponent as ApplicationsIcon } from '../../assets/svg/WeKnow_Applications.svg';
import { useAppContext } from '../../store/app-context';
import useCookies from '../../hooks/useCookies';

interface Props {
  isOpened: (value: boolean) => void;
}

function SwitchApplications({ isOpened }: Readonly<Props>): JSX.Element {
  const { appData, switchProject } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const { cookies, setCookie } = useCookies();
  const showAppSwitcherPulse = cookies.get('isAppSwitcherUsed') !== 'true';

  const openPopup = (): void => {
    setIsOpen(true);
    isOpened(true);
    setCookie('isAppSwitcherUsed', 'true', 90);
  };
  const closePopup = (): void => {
    setIsOpen(false);
  };

  const possiblySwitchProject = (prjId: number): void => {
    if (prjId !== appData.projectId) {
      switchProject(prjId);
    }
  };

  return (
    <div className="flex">
      <button
        type="button"
        onClick={openPopup}
        className={`fill-white rounded cursor-pointer transition ${
          !showAppSwitcherPulse && 'hover:bg-white hover:bg-opacity-10'
        } ${showAppSwitcherPulse && 'bg-weknow-default animate-pulse'}`}>
        <ApplicationsIcon className="w-10" />
      </button>
      {isOpen && (
        <>
          <div
            className="bg-black bg-opacity-80 absolute top-0 left-0 right-0 bottom-0 cursor-auto"
            onClick={closePopup}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                closePopup();
              }
            }}
            tabIndex={0}
            role="button"
            aria-label="Close"
          />
          <div className="absolute left-3 right-3 top-16 flex items-center justify-center">
            <div className="bg-weknow-dark w-full p-4 rounded shadow-md relative z-10">
              <h3 className="font-semibold text-lg text-white">
                Select an application
              </h3>
              <div className="mt-2 mb-4 h-[1px] bg-gray-400 opacity-100 dark:opacity-50" />
              {appData.projects
                .filter((project) => project.prj_is_switchable)
                .map((project) => (
                  <div key={project.prj_id} className="flex items-center">
                    <NavLink
                      to={`/${project.prj_label}/`}
                      onClick={() => possiblySwitchProject(project.prj_id)}
                      className={() => {
                        const classes = [
                          'group flex items-center px-4 py-2 my-1 text-sm font-medium rounded-md transition',
                        ];
                        if (project.prj_id === appData.projectId) {
                          classes.push(
                            'bg-black bg-opacity-50 text-white cursor-default'
                          );
                        } else {
                          classes.push(
                            'text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white'
                          );
                        }
                        return classes.join(' ');
                      }}>
                      {project.prj_name}
                    </NavLink>
                  </div>
                ))}
              <div className="mt-4 mb-2 h-[1px] bg-gray-400 opacity-100 dark:opacity-50" />
              <button
                type="button"
                onClick={closePopup}
                className="bg-weknow-darker text-white rounded px-4 py-1 mt-4 transition hover:bg-opacity-70">
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SwitchApplications;
